import Logo from "@/assets/Logo-Primary.svg";
import STATUS_SPAN from "./constant";

const Header = ({ status, home = false }) => {
  return (
    <div className="flex flex-col items-center gap-2">
      <img className="w-1/5" alt="logo" src={Logo} />
      {home && (
        <>
          <h1 className="text-2xl font-semibold mt-5">Bienvenue dans la création de votre dossier</h1>
          <h2 className="text-sm text-gray-500 mt-2">Saisissez les informations manquantes. Votre dossler sera transmis à l'agence lorsqu'il sera complet</h2>
        </>
      )}
      <div className="">
        <div className="text-sm text-primary font-semibold mt-2 flex items-center gap-4">
          <p>Statut du dossier :</p>
          {STATUS_SPAN[status === "INCOMPLETED" ? "INCOMPLETED" : "COMPLETED"]}
        </div>
      </div>
    </div>
  );
};

export default Header;
